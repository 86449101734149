@import '../scss/colors.scss';
@import '../scss/font.scss';
@import '../scss/animations.scss';

#home {
    position: relative;
    background-color: $azul-escuro-2;
    background-size: cover;
    height: 100vh;
    overflow: hidden;
    font-family: 'sofia-pro', sans-serif;

    .video {
        position: absolute;
        top: 0;
        width: 100%;
        overflow: hidden;
    }

    .spinner {
        position: absolute;
        top: 50%;
        left: 50%;
    }
    
    .home__logo {
        position: absolute;
        top: 40%;
        left: 40%;
        width: 20%;
    }
}

.home {
    margin-top: 5vw;

}



@media only screen and (max-width: 767px) {

    #home {
        min-height: 100vh;
        height: unset;

        .video {
            width: unset;
            background-attachment: fixed;
        }

        .home__logo {
            top: 50%;
            width: 40%;
            left: 30%;
        }
    }


    .home {
        padding-left: 5vw;
        padding-right: 5vw;
        margin-top: 20vw;
    }

}