@import '../scss/colors.scss';
@import '../scss/font.scss';
@import '../scss/animations.scss';

#sobre {
    position: relative;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
}

#sobre2 {
    background-position: center right;
    background-color: $azul-claro-2;
}

.sobre {
    margin-top: 5vw;
    padding: 4vw;
    background-color: $azul-medio;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center left;
    height: 34vw;
    margin-bottom: 4vw;
    border-radius: 36px;

    .sobre__title {
        font-size: 2vw;
        color: $azul-claro;
        font-weight: 700;
        font-family: "unbounded", sans-serif;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 2vw;
    }

    .title--2 {
        color: $azul-escuro;
    }

    .sobre__text {
        font-size: 1vw;
        color: $azul-escuro;
        font-weight: 600;
        font-family: "europa", sans-serif;
    }

}

#text-2 {
    font-size: 1.15vw;
}

@media only screen and (max-width: 767px) {


    .sobre {
        margin-top: 20vw;
        padding: 12vw;
        height: unset;
        margin-bottom: 4vw;
        margin-left: 7.5vw;
        margin-right: 7.5vw;
    
        .sobre__title {
            font-size: 8vw;
            margin-bottom: 12vw;
            margin-top: 8vw;
        }
    
        .sobre__text {
            font-size: 4.25vw;
            text-align: left;
        }

        
        
    }
    
    #text-2 {
        font-size: 4.25vw;
        text-align: left;
    }

}